<template>
  <div class="dev-onboard">
    <Navbar />
    <main>
      <div
        v-if="isError == false"
        style="left: 50%; right: 0; margin-top: 20%"
        id="loaderCal"
      >
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        Syncing Calender
      </div>

      <center
        v-if="isError == true"
        style="
          width: 450px;
          align-self: center;
          border: 1px solid #ccc;
          margin-left: auto;
          margin-right: auto;
          border-radius: 10px;
          padding-bottom: 20px;
          margin-top: 40px;
        "
      >
        <div
          style="text-align: left; border-bottom: 1px solid #ccc; padding: 10px"
        >
          <img src="/img/logo3.png" style="width: 50px" />
        </div>

        <div style="margin-top: 15%">
          <img src="/img/google_calendar.png" style="width: 150px" />
          <h1
            style="
              color: #777a81;
              padding-bottom: 0;
              padding-top: 16px;
              font-family: Google Sans, Noto Sans Myanmar UI, arial, sans-serif;
              font-size: 17px;
              font-weight: 400;
              line-height: 1.3333;
              margin-bottom: 0;
              margin-top: -20px;
              word-break: break-word;
            "
          >
            Access Needed
          </h1>

          <img
            onclick="history.back()"
            src="/img/calendar_scope.png"
            style="
              width: 334px;
              margin-top: 80px;
              border: 1px solid #ccc;
              border-radius: 10px;
              padding: 20px;
              cursor: pointer;
            "
          />

          <div
            style="
              width: 356px;
              font-family: Google Sans, Noto Sans Myanmar UI, arial, sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 150%;
              color: #777a81;
              margin-top: 30px;
            "
          >
            Grupa will only use the requested calendar access to view when you're 
                    available, and set up conversations with Experts on your behalf. 
                    <b>Grupa will not access your calendar or event data for any other purpose.</b>

            <div class="alert alert-warning" role="alert">
              Kindly click on the checkbox to synch your calendar
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            gap: 20px;
            justify-content: center;
            margin-top: 60px;
          "
        >
          <div
            style="
              width: 180px;
              font-family: Google Sans, Noto Sans Myanmar UI, arial, sans-serif;
              color: #1a73e8;
              border: 1px solid #ccc;
              text-align: center;
              border-radius: 5px;
              padding: 14px;
              cursor: pointer;
            "
            @click="cancelButton()"
          >
            Cancel
          </div>

          <div
            onclick="history.back()"
            style="
              width: 180px;
              font-family: Google Sans, Noto Sans Myanmar UI, arial, sans-serif;
              color: #fff;
              border: 1px solid #ccc;
              text-align: center;
              border-radius: 5px;
              padding: 14px;
              background-color: #1a73e8;
              cursor: pointer;
            "
          >
            Sync Calendar
          </div>
        </div>
      </center>

      <div class="successCheck" style="display: none; margin-top: 15%">
        <img src="/img/calendar.png" style="width: 200px" />
        <div style="margin-top: 30px" id="nylasLoader2">
          Calendar sync completed
        </div>

        <div style="margin-top: 10px; color: #aaa; font-size: 11px">
          redirecting in few seconds
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Axios from "axios";
import { mapState, mapActions } from "vuex";
import postLogin from "@/mixins/postLogin";
import Navbar from "@/components/Navbar/onboarding_navbar";
import { getNylasAccessToken } from "@/api/nylasApi";

export default {
  // mixins: [postLogin, mixPanel],
  components: {
    Navbar,
  },
  data: () => ({
    nylasCode: "",
    isRequestingCode: false,
    isError: false,
  }),
  created() {},
  mounted() {
    var url_string = document.location.href; //window.location.href
    var url = new URL(url_string);
    var c = url.searchParams.get("code");
    console.log(c);
    //this.nylasCode = c;

    this.getAcessToken(c);
    // alert(c);
  },
  methods: {
    cancelButton() {
      window.opener.document.location.reload();
      window.open(location, "_self").close();
      window.close();
      $("#loaderCal").hide();
    },
    getAcessToken(c) {
      let payload = {
        code: c,
      };

      console.log(payload);

      this.isRequestingCode = true;
      getNylasAccessToken(payload)
        .then((data) => {
          let InnterVal = setInterval(() => {
            $("#nylasLoader2").fadeToggle(2000);
          }, 1500);

          console.log("Sussess", data.data);
          if (data.data.data.response === null) {
            this.isError = true;
            // alert("An Unexpected Error Occured\nKindly retry profile setup");
            // window.opener.document.location.reload();
            // window.open(location, "_self").close();
            // window.close();
            $("#loaderCal").hide();
          } else {
            $("#loaderCal").fadeOut(() => {
              $(".successCheck").fadeIn();

              window.opener.$(".step2").fadeOut(() => {
                window.opener.$(".step3").fadeIn();
              });

              let timer = setTimeout(() => {
                clearTimeout(timer);
                clearInterval(InnterVal);

                // window.opener.$("#showNylasBar").modal("hide");

                //

                // window.opener.postMessage("location", "*");
                window.opener.document.location.reload();
                window.open(location, "_self").close();
                window.close();
              }, 3000);
            });
            //

            //window.opener.postMessage("location", "*");
            //window.open(location, "_self").close();
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
  },
  computed: {},
};
</script>
<style scoped></style>
