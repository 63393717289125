import Axios from "axios";
let baseURL = process.env.VUE_APP_BACKEND_API;

const axios = Axios.create({
  baseURL,
  timeout: 100000,
});

axios.interceptors.request.use(
  function (config) {
    const user = JSON.parse(localStorage.getItem("grupa_user"));
    config.headers.Authorization = `Bearer ${user.token}`;
    config.headers.common["Access-Control-Allow-Origin"] = "*";
    config.headers.common["Content-Type"] = `application/json`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const connectGrupaToNylas = (payload) => {
  return axios.post(
    `${baseURL}/connect/nylas/nylas_authentication_curl`,
    payload
  );
};

export const getGrupaNylasRecord = (payload) => {
  return axios.post(
    `${baseURL}/connect/nylas/get_nylas_record_record`,
    payload
  );
};

export const getNylasAccessToken = (payload) => {
  return axios.post(
    `${baseURL}/connect/nylas/get_nylas_access_token`,
    payload
  );
};

export const getNylasFreeBusy = (payload) => {
  return axios.post(
    `${baseURL}/connect/nylas/nylas_free_busy_step_1`,
    payload
  );
};

export const getNylasAvailability = (payload) => {
  return axios.post(
    `${baseURL}/connect/nylas/nylas_availability_step_2`,
    payload
  );
};



export const sendCalnderInvitation = (payload) => {
  return axios.post(
    `${baseURL}/connect/nylas/send_calnder_invitation`,
    payload
  );
};
