var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dev-onboard" },
    [
      _c("Navbar"),
      _c(
        "main",
        [
          _vm.isError == false
            ? _c(
                "div",
                {
                  staticStyle: { left: "50%", right: "0", "margin-top": "20%" },
                  attrs: { id: "loaderCal" },
                },
                [
                  _c("v-progress-circular", {
                    attrs: { size: 30, color: "primary", indeterminate: "" },
                  }),
                  _vm._v("\n      Syncing Calender\n    "),
                ],
                1
              )
            : _vm._e(),
          _vm.isError == true
            ? _c(
                "center",
                {
                  staticStyle: {
                    width: "450px",
                    "align-self": "center",
                    border: "1px solid #ccc",
                    "margin-left": "auto",
                    "margin-right": "auto",
                    "border-radius": "10px",
                    "padding-bottom": "20px",
                    "margin-top": "40px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "border-bottom": "1px solid #ccc",
                        padding: "10px",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "50px" },
                        attrs: { src: "/img/logo3.png" },
                      }),
                    ]
                  ),
                  _c("div", { staticStyle: { "margin-top": "15%" } }, [
                    _c("img", {
                      staticStyle: { width: "150px" },
                      attrs: { src: "/img/google_calendar.png" },
                    }),
                    _c(
                      "h1",
                      {
                        staticStyle: {
                          color: "#777a81",
                          "padding-bottom": "0",
                          "padding-top": "16px",
                          "font-family":
                            "Google Sans, Noto Sans Myanmar UI, arial, sans-serif",
                          "font-size": "17px",
                          "font-weight": "400",
                          "line-height": "1.3333",
                          "margin-bottom": "0",
                          "margin-top": "-20px",
                          "word-break": "break-word",
                        },
                      },
                      [_vm._v("\n          Access Needed\n        ")]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "334px",
                        "margin-top": "80px",
                        border: "1px solid #ccc",
                        "border-radius": "10px",
                        padding: "20px",
                        cursor: "pointer",
                      },
                      attrs: {
                        onclick: "history.back()",
                        src: "/img/calendar_scope.png",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "356px",
                          "font-family":
                            "Google Sans, Noto Sans Myanmar UI, arial, sans-serif",
                          "font-style": "normal",
                          "font-weight": "500",
                          "font-size": "12px",
                          "line-height": "150%",
                          color: "#777a81",
                          "margin-top": "30px",
                        },
                      },
                      [
                        _vm._v(
                          "\n          Grupa will only use the requested calendar access to view when you're \n                  available, and set up conversations with Experts on your behalf. \n                  "
                        ),
                        _c("b", [
                          _vm._v(
                            "Grupa will not access your calendar or event data for any other purpose."
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "alert alert-warning",
                            attrs: { role: "alert" },
                          },
                          [
                            _vm._v(
                              "\n            Kindly click on the checkbox to synch your calendar\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        gap: "20px",
                        "justify-content": "center",
                        "margin-top": "60px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "180px",
                            "font-family":
                              "Google Sans, Noto Sans Myanmar UI, arial, sans-serif",
                            color: "#1a73e8",
                            border: "1px solid #ccc",
                            "text-align": "center",
                            "border-radius": "5px",
                            padding: "14px",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.cancelButton()
                            },
                          },
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "180px",
                            "font-family":
                              "Google Sans, Noto Sans Myanmar UI, arial, sans-serif",
                            color: "#fff",
                            border: "1px solid #ccc",
                            "text-align": "center",
                            "border-radius": "5px",
                            padding: "14px",
                            "background-color": "#1a73e8",
                            cursor: "pointer",
                          },
                          attrs: { onclick: "history.back()" },
                        },
                        [_vm._v("\n          Sync Calendar\n        ")]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._m(0),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "successCheck",
        staticStyle: { display: "none", "margin-top": "15%" },
      },
      [
        _c("img", {
          staticStyle: { width: "200px" },
          attrs: { src: "/img/calendar.png" },
        }),
        _c(
          "div",
          {
            staticStyle: { "margin-top": "30px" },
            attrs: { id: "nylasLoader2" },
          },
          [_vm._v("\n        Calendar sync completed\n      ")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "10px",
              color: "#aaa",
              "font-size": "11px",
            },
          },
          [_vm._v("\n        redirecting in few seconds\n      ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }